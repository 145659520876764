import "./AboutUsSection.css";

const AboutUsSection = () => {
  return (
    <div className="about-us-container">
      <h1 className="founders-title">Meet the founders</h1>
      <div className="founders-description">
        We are two Brown University graduates who are deeply passionate about
        helping students and educators. Combined, we have taught dozens of
        courses and hundreds of students across the US.
      </div>
      <div className="content-container">
        <div className="image-container">
          <img
            src="/Assets/AboutPhotos.png"
            alt="About the Founders"
            className="rounded-lg"
          />
        </div>
        <div className="text-container">
          <div className="text-section-about">
            <div className="text-content-about">
              <div className="text-title-about">What have we learned?</div>
              <div className="text-description-about">
                Every school is grappling with the implications of bringing AI
                into the classroom. Out of all educators, humanities
                teachers—who teach students to communicate through writing—are
                struggling the most. Students can now copy-and-paste information
                from a chatbot instead of submitting original work. Teachers are
                spending hours on detecting and combating AI plagiarism.{" "}
              </div>
            </div>
          </div>

          <div className="text-section-about">
            <div className="text-content-about">
              <div className="text-title-about">
                What are we going to do about it?
              </div>
              <div className="text-description-about">
                GuidedYou allows teachers to move existing writing assignments
                onto a plagiarism-resistant environment where their students
                will be assisted by AI. Students don't learn when they cheat,
                and we want students to learn. By responsibly and gradually
                introducing AI to the classroom, we frame it as an amazing
                learning tool.
              </div>
            </div>
          </div>

          <div className="text-section-about">
            <div className="text-content-about">
              <div className="text-title-about">
                Why do we believe in GuidedYou?
              </div>
              <div className="text-description-about">
                Educators work hard, and AI plagiarism only makes it harder. We
                can help them focus on what they do best: helping students
                learn.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsSection;
