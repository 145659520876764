import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { ContentType } from './BlogContentType';
import { BlogPost } from './BlogPostInterface';
import './BlogPost.css'

// Define the structure of a blog post

const renderContent = (content: ContentType[]) => {
  return content.map((item, index) => {
    switch (item.type) {
      case 'paragraph':
        return (
          <p key={index}>
            {item.children && renderContent(item.children)}
          </p>
        );
      case 'emphasis':
        return <em key={index}>{item.text}</em>;
      case 'link':
        return (
          <a key={index} href={item.url} target="_blank" rel="noopener noreferrer" className="blue-link">
            {item.text}
          </a>
        );
      case 'text':
        return <span key={index}>{item.text}</span>;
      default:
        return null;
    }
  });
};


const BlogPostComponent = () => {
  const [post, setPost] = useState<BlogPost | null>(null);
  const { postId } = useParams<{ postId: string }>();

  useEffect(() => {
    // Fetch the entire blog data then find the specific post by ID
    fetch('/Assets/blogData.json')
      .then((response) => response.json())
      .then((data: BlogPost[]) => {
        // Find the post by ID
        const foundPost = data.find((p) => p.id.toString() === postId);
        setPost(foundPost || null);
      })
      .catch((error) => {
        console.error('Error fetching blog data:', error);
      });
  }, [postId]); // Dependency array with postId to refetch when postId changes

  if (!post) {
    return <div>Loading...</div>; // Or some loading indicator
  }

  return (
    <div className="site-container">
      <header className="header">
        <Link to="/"><div className="title"><img src="/Assets/Title.png" alt="A friendly cartoon character with round glasses and a smiling face, representing Ed, the personal learning companion." className="rounded-lg" /></div></Link>
      </header>
      <div className="post-container">
        <div className="blog-header">
          <img src={post.imageUrl} alt={post.imageAlt} />
          <p className="image-caption">{post.imageCaption}</p>
          <h1 className="blog-title">{post.title}</h1>
          <p className="author-date">{post.author} · {post.date}</p>
        </div>
        <div className="blog-content">
          {Array.isArray(post.content) ? renderContent(post.content) : <p>{post.content}</p>}
        </div>
      </div>
    </div>
  );
};

export default BlogPostComponent;
