import "./ForYouSection.css";

const ForYouSection = () => {
  return (
    <div className="outer-for-you-container">
      <div className="main-for-you-container">
        <div className="for-you-title">Why GuidedYou?</div>
        <div className="relative-container">
          <div className="blue-background"></div>
          <div className="white-box first">
            <img src="/Assets/LearnImage.png" alt="A whale" />
            <div className="box-content">
              <div className="text-large">Save time</div>
              <div className="text-small">
                Dealing with plagiarism in the age of AI is tiring. AI detector
                scores are not trustworthy and flagged assignments lead to a
                long investigative process. With concise student activity
                reports, GuidedYou can help you keep track of online submissions
                without worrying about restructuring your entire curriculum or
                grading messy handwriting.{" "}
              </div>
            </div>
          </div>
          <div className="white-box second">
            <img src="/Assets/OrganizeImage.png" alt="An abacus" />
            <div className="box-content">
              <div className="text-large">Reduce plagiarism</div>
              <div className="text-small">
                AI can be immensely helpful for student learning, but students
                should never be copying something word-for-word from an external
                source. Our writing environment will remind students that they
                should be writing original content only and allow you to
                reference activity reports for concrete evidence of their work.{" "}
              </div>
            </div>
          </div>
          <div className="white-box third">
            <img src="/Assets/ChatImage.png" alt="A laptop" />
            <div className="box-content">
              <div className="text-large">Increase student achievement</div>
              <div className="text-small">
                GuidedYou provides a safe chatbot for students to ask questions
                about writing prompts and brainstorm ideas. When they submit
                assignments, you’ll receive chat summaries to help understand
                exactly where some extra guidance was needed.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForYouSection;
