import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import './LandingPage.css';
import ForYouSection from './ForYouSection';
import MainSection from './MainSection';
import AboutUsSection from './AboutUsSection';
import BlogSection from './BlogSection';


const LandingPage: React.FC = () => {

  const forYouSectionRef = useRef(null);
  const aboutUsSectionRef = useRef(null);
  const blogSectionRef = useRef(null);

  // Common function to handle scrolling to any section
  const handleNavClick = (ref: React.RefObject<HTMLElement>) => {
    if (ref.current) {
      const headerOffset = 60; // The height of your fixed header
      const elementPosition = ref.current.offsetTop;
      const offsetPosition = elementPosition - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };

  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <div className="landing-page">
      {/* Header Section */}
      <header className="header">
        {/* Make the div clickable and attach the scrollToTop function */}
        <div className="title">
          <Link to="/" onClick={scrollToTop}>
            <span className="guided">Guided</span>
            <span className="you">You</span>
          </Link>
        </div>
        <div className="header-content">
          {/* In-line Navigation Links */}
          <nav className="inline-nav">
            <button className="nav-button" onClick={() => handleNavClick(forYouSectionRef)}>For Teachers</button>
            <button className="nav-button" onClick={() => handleNavClick(aboutUsSectionRef)}>About Us</button>
            <button className="nav-button" onClick={() => handleNavClick(blogSectionRef)}>Blog</button>
          </nav>

          <div className="menu-icon" onClick={toggleMenu} aria-label="Open Menu">
            ☰
          </div>

          {/* Side Menu */}
          <div className={`side-menu ${menuOpen ? 'open' : ''}`}>
            <button className="nav-button" onClick={() => handleNavClick(forYouSectionRef)}>For Teachers</button>
            <button className="nav-button" onClick={() => handleNavClick(aboutUsSectionRef)}>About Us</button>
            <button className="nav-button" onClick={() => handleNavClick(blogSectionRef)}>Blog</button>
          </div>
        </div>
      </header>
      <MainSection />
      <section id="for-you" ref={forYouSectionRef}>
        <ForYouSection />
      </section>
      <section id="about-us" ref={aboutUsSectionRef}>
        <AboutUsSection />
      </section>
      <section id="blog" ref={blogSectionRef}>
        <BlogSection />
      </section>
      <footer className="footer">
        <div className="footer-container">
          <div className="logo-and-title-container">

            <Link to="/" onClick={scrollToTop} className="title-container">
              <img src="/Assets/Ed.png" alt="GuidedYou Logo" className="logo" />
              <span className="guided-text">Guided</span>
              <span className="you-text">You</span>
            </Link>
          </div>
          <div className="menu-container">
            <Link to="/" onClick={scrollToTop}>
              <div className="menu-item">Home</div>
            </Link>
            <button className="menu-item" onClick={() => handleNavClick(forYouSectionRef)}>For Teachers</button>
            <button className="menu-item" onClick={() => handleNavClick(aboutUsSectionRef)}>About Us</button>
            <button className="menu-item" onClick={() => handleNavClick(blogSectionRef)}>Blog</button>
            <div className="menu-item"><a href="/privacypolicy.html" target="_blank">Privacy Policy</a>
            </div>
          </div>
          <div className="copyright">© 2024 GuidedYou, Inc. | All rights reserved.</div>
        </div >
        <div className="blue-background-footer"></div>
      </footer>
    </div>
  );
};

export default LandingPage;

