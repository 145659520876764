import './BlogSection.css'
import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BlogPost } from './BlogPostInterface';

const BlogSection = () => {

  const [blogPosts, setBlogPosts] = useState<BlogPost[]>([]);
  const postsPerPage = 3;

  useEffect(() => {
    fetch('/Assets/blogData.json')
      .then((response) => response.json())
      .then((data) => {
        setBlogPosts(data); // Set the blog posts state
      })
      .catch((error) => {
        console.error('Error fetching blog data:', error);
      });
  }, []);
  const carouselRef = useRef<HTMLDivElement>(null);

  const scrollToIndex = (index: number) => {
    const carousel = carouselRef.current;
    if (carousel) {
      const width = carousel.offsetWidth;
      carousel.scrollTo({
        left: width * index,
        behavior: 'smooth'
      });
    }
  };

  const nextSlide = () => {
    const carousel = carouselRef.current;
    if (carousel) {
      const newIndex = (carousel.scrollLeft / carousel.offsetWidth + postsPerPage) % blogPosts.length;
      scrollToIndex(newIndex);
    }
  };

  const prevSlide = () => {
    const carousel = carouselRef.current;
    if (carousel) {
      const newIndex = (carousel.scrollLeft / carousel.offsetWidth - (postsPerPage + blogPosts.length)) % blogPosts.length;
      scrollToIndex(newIndex);
    }
  };


  return (
    <div className="blog-container bg-black min-h-screen flex flex-col items-center justify-center p-4">
      <h1 className="blog-section-title">Blog</h1>
      <div className="blog-section-description">
        See what we've had to say about AI and education
      </div>
      <div className="relative w-full">
        <div ref={carouselRef} className="carousel-container">
          {blogPosts.map((post, index) => (
            <div key={index} className="carousel-item p-4">
              <div className="bg-white p-8 rounded-lg shadow-lg h-full">
                <img
                  src={post.imageUrl}
                  alt={post.imageAlt}
                  className="mb-6"
                />
                <h2 className="text-3xl font-bold mb-2">
                  <Link to={`/blog/${index}`} className="text-blue-600 hover:text-blue-800">
                    {post.title}
                  </Link>
                </h2>
                <p className="text-gray-600 mb-4">{post.date} · {post.author}</p>
              </div>
            </div>
          ))}
        </div>
        <button onClick={prevSlide} className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-lg z-10">
          <i className="fas fa-chevron-left"></i>
        </button>
        <button onClick={nextSlide} className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-white rounded-full p-2 shadow-lg z-10">
          <i className="fas fa-chevron-right"></i>
        </button>
      </div>
    </div>
  );
}

export default BlogSection;